// src/pages/Main.js

import React, { useState, useEffect, useMemo } from "react";

import {
  TextField,
  Button,
  Box,
  Card,
  TableContainer,
  Paper,
  TableHead,
  TableCell,
  TableBody,
  Table,
  TableRow,
  Autocomplete,
  CircularProgress,
  ListItem,
  Typography,
  Grid,
  useMediaQuery,
} from "@mui/material";
import HomePage from "./HomePage";
import { useTheme } from "@mui/material/styles";
import axios from "axios";
import VirtualizedList from "../components/VirtualizedList";
import { debounce } from "lodash";

const Main = () => {
 
  const [error, setError] = useState(null);
  const [statusMsg, setStatusMsg] = useState("");
  const [date1, setDate1] = useState("");
  const [date2, setDate2] = useState("");
  const [date3, setDate3] = useState("");
  const [dailyData, setDailyData] = useState([]);
  const [fifteenMinData, setFifteenMinData] = useState([]);
  const [oneMinData, setOneMinData] = useState([]);
  const [currentData, setCurrentData] = useState([]);
  const [currentHeaders, setCurrentHeaders] = useState([]);
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [symbolOptions, setSymbolOptions] = useState([]);
  const [selectedSymbol, setSelectedSymbol] = useState(null);
  const [inputValue, setInputValue] = useState("");
  const [loading, setLoading] = useState(false);
  const [searchError, setSearchError] = useState(null);
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));


  // Function to extract headers dynamically
  const getHeaders = (dataArray) => {
    if (dataArray.length === 0) return [];
    const headersSet = new Set();
    dataArray.forEach((item) => {
      Object.keys(item).forEach((key) => headersSet.add(key));
    });
    const headers = Array.from(headersSet);

    return headers;
  };

  useEffect(() => {
    // Update headers whenever currentData changes
    if (currentData.length > 0) {
      const extractedHeaders = getHeaders(currentData);

      

      if (JSON.stringify(currentHeaders) !== JSON.stringify(extractedHeaders)) {
        setCurrentHeaders(extractedHeaders);
      }
    } else {
      setCurrentHeaders([]);
    }
  }, [currentData]);

 
  const handleDownloadClick = async () => {
    if (!selectedSymbol || !date1 || !date2 || !date3) {
      setError("All fields are required.");
      return;
    }
    setIsLoading(true);
    setError(null);
    setStatusMsg("");
    try {
      const userId = localStorage.getItem("userId");
      
      console.log(" To  date "+ date3);
      
      const payload = {
        selectedSymbol: selectedSymbol.symTicker,
        date1: date1,
        date2: date2,
        date3: date3,
        userId: userId,
      };

      const response = await axios.post(
        "https://api.arasu.online/api/Data/DownloadAndProcess",
        payload,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

    
      const historicalData = response.data.historicalData || {};

      if (JSON.stringify(dailyData) !== JSON.stringify(historicalData.dailyData)) {
        setDailyData(historicalData.dailyData || []);
      }
      
      if (JSON.stringify(fifteenMinData) !== JSON.stringify(historicalData.fifteenMinData)) {
        setFifteenMinData(historicalData.fifteenMinData || []);
      }
      
      if (JSON.stringify(oneMinData) !== JSON.stringify(historicalData.oneMinData)) {
        setOneMinData(historicalData.oneMinData || []);
      }
      
      setStatusMsg("Data downloaded and processed successfully.");
    } catch (error) {
      console.error("Error fetching data:", error);
      setError("Failed to download data. Please try again.");
    } finally {
      setIsLoading(false);
    }
  };

  // New function to handle saving data to the database
  const handleSaveToDB = async () => {
    if (!selectedSymbol) {
      setError("Please select a symbol before saving data.");
      return;
    }

    if (dailyData.length === 0 && fifteenMinData.length === 0 && oneMinData.length === 0) {
      setError("No data available to save. Please download data first.");
      return;
    }

    setIsLoading(true);
    setError(null);
    setStatusMsg("");

    try {
      const token = localStorage.getItem("token");
      const userId = localStorage.getItem("userId");

      const payload = {
        symbol: selectedSymbol.symTicker,
        dailyData: dailyData,
        fifteenMinData: fifteenMinData,
        oneMinData: oneMinData,
        userId: userId,
      };

      const response = await axios.post(
        "https://api.arasu.online/api/Data/SaveToDB",
        payload,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );

      console.log(response.data);
      setStatusMsg("Data saved to database successfully.");
    } catch (error) {
      console.error("Error saving data to DB:", error);
      setError("Failed to save data to database. Please try again.");
    } finally {
      setIsLoading(false);
    }
  };

  // Functions to handle showing data
  const handleClickDailyData = () => {
    if (currentData !== dailyData) {
      setCurrentData(dailyData);
    }
  };

  const handleClickFifteenMinData = () => {
    if (currentData !== fifteenMinData) {
      setCurrentData(fifteenMinData);
    }
  };

  const handleClickOneMin = () => {

    if (currentData !== oneMinData) {
      setCurrentData(oneMinData);
    }
    
  };

  // Debounce the API call to prevent excessive requests
  const fetchSymbolOptions = useMemo(
    () =>
      debounce(async (query) => {
        if (!query || query.trim().length < 1) {
          setSymbolOptions([]);
          return;
        }
        setLoading(true);
        setSearchError(null);
        try {
          const token = localStorage.getItem("token");
          const response = await axios.get(
            "https://api.arasu.online/api/Symbol/Search",
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
              params: { query: query },
            }
          );

          if (response.data && Array.isArray(response.data)) {
            setSymbolOptions(response.data);
          } else {
            setSymbolOptions([]);
          }
        } catch (err) {
          console.error("Error fetching symbol options:", err);
          setSearchError("Failed to fetch symbols.");
        } finally {
          setLoading(false);
        }
      }, 300),
    []
  );

  const handleInputChange = (event, newInputValue) => {
    setInputValue(newInputValue);
    fetchSymbolOptions(newInputValue);
  };

  // Handle symbol selection
  const handleSymbolChange = (event, newValue) => {
    setSelectedSymbol(newValue);
  };

  // Function to handle sorting
  const handleSort = (column) => {
    const isAsc = orderBy === column && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(column);
    const sortedData = [...currentData].sort((a, b) => {
      const aValue =
        a[column] !== undefined && a[column] !== null ? a[column] : "";
      const bValue =
        b[column] !== undefined && b[column] !== null ? b[column] : "";

      // Convert string numbers to actual numbers for comparison
      const aNumber = parseFloat(aValue);
      const bNumber = parseFloat(bValue);
      if (!isNaN(aNumber) && !isNaN(bNumber)) {
        return isAsc ? aNumber - bNumber : bNumber - aNumber;
      }

      const aString = aValue.toString().toLowerCase();
      const bString = bValue.toString().toLowerCase();
      if (aString < bString) {
        return isAsc ? -1 : 1;
      }
      if (aString > bString) {
        return isAsc ? 1 : -1;
      }
      return 0;
    });
    setCurrentData(sortedData);
  };

  const sortedData = useMemo(() => {
    return [...currentData].sort((a, b) => {
      if (!orderBy) return 0; // If no sorting column, return data as is
  
      const aValue = a[orderBy] !== undefined ? a[orderBy] : "";
      const bValue = b[orderBy] !== undefined ? b[orderBy] : "";
  
      const aNumber = parseFloat(aValue);
      const bNumber = parseFloat(bValue);
      
      if (!isNaN(aNumber) && !isNaN(bNumber)) {
        return order === "asc" ? aNumber - bNumber : bNumber - aNumber;
      }
  
      return order === "asc"
        ? aValue.toString().localeCompare(bValue.toString())
        : bValue.toString().localeCompare(aValue.toString());
    });
  }, [currentData, orderBy, order]);
  

  return (
    <>
      <HomePage />
      <Box sx={{ display: "flex", justifyContent: "center", mt: 5, px: 2 }}>
        <Box
          sx={{
            width: { width:"530px" },
            p: 4,
            borderRadius: "16px",
            boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.2)",
            backgroundColor: "white",
            margin: "auto",
          }}
        >
          {/* Symbol Selection */}
          <Autocomplete
            options={symbolOptions}
            getOptionLabel={(option) => option.symTicker}
            value={selectedSymbol}
            onChange={handleSymbolChange}
            inputValue={inputValue}
            onInputChange={handleInputChange}
            loading={loading}
            ListboxComponent={VirtualizedList}
            renderOption={(props, option) => (
              <ListItem {...props} key={option.symTicker}>
                {option.symTicker}
              </ListItem>
            )}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Search Symbol"
                variant="outlined"
                fullWidth
                InputProps={{
                  ...params.InputProps,
                  endAdornment: (
                    <>
                      {loading ? (
                        <CircularProgress color="inherit" size={20} />
                      ) : null}
                      {params.InputProps.endAdornment}
                    </>
                  ),
                }}
              />
            )}
            noOptionsText={
              inputValue.trim().length < 1
                ? "Type at least 1 character"
                : "No symbols found"
            }
            sx={{ mb: 2 }}
          />
          {searchError && (
            <Typography
              variant="body2"
              color="error"
              sx={{ marginTop: theme.spacing(2), textAlign: "center" }}
            >
              {searchError}
            </Typography>
          )}
          {/* Date Inputs */}
          <Grid container spacing={2}>
            <Grid item xs={12} >
              <TextField
                label="Select Date for Daily Close Price"
                type="date"
                variant="outlined"
                fullWidth
                InputLabelProps={{ shrink: true }}
                sx={{ mb: 2 }}
                value={date1 || ""}
                onChange={(e) => setDate1(e.target.value)}
              />
            </Grid>
            <Grid item xs={12} >
              <TextField
                label="Select From Date (15m/1m Interval)"
                type="date"
                variant="outlined"
                fullWidth
                InputLabelProps={{ shrink: true }}
                sx={{ mb: 2 }}
                value={date2 || ""}
                onChange={(e) => setDate2(e.target.value)}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="Select To Date (15m/1m Interval)"
                type="date"
                variant="outlined"
                fullWidth
                InputLabelProps={{ shrink: true }}
                sx={{ mb: 2 }}
                value={date3 || ""}
                onChange={(e) => setDate3(e.target.value)}
              />
            </Grid>
          </Grid>
          {/* Action Buttons */}
          <Box
            sx={{
              display: "flex",
              flexDirection: isSmallScreen ? "column" : "row",
              justifyContent: "space-between",
              mb: 2,
            }}
          >
            <Button
              variant="contained"
              color="primary"
              sx={{
                flexGrow: 1,
                mr: isSmallScreen ? 0 : 1,
                mb: isSmallScreen ? 1 : 0,
              }}
              onClick={handleClickDailyData}
              disabled={dailyData.length === 0}
            >
              Show Daily Data
            </Button>
            <Button
              variant="contained"
              color="primary"
              sx={{
                flexGrow: 1,
                mr: isSmallScreen ? 0 : 1,
                mb: isSmallScreen ? 1 : 0,
              }}
              onClick={handleClickFifteenMinData}
              disabled={fifteenMinData.length === 0}
            >
              Show 15 Min Data
            </Button>
            <Button
              variant="contained"
              color="primary"
              sx={{
                flexGrow: 1,
              }}
              onClick={handleClickOneMin}
              disabled={oneMinData.length === 0}
            >
              Show 1 Min Data
            </Button>
          </Box>
          {/* Download Button */}
          <Button
            variant="contained"
            color="primary"
            fullWidth
            sx={{
              mb: 2,
              py: 1.5,
              fontWeight: "bold",
              backgroundColor: "#1976d2",
              "&:hover": {
                backgroundColor: "#115293",
              },
            }}
            onClick={handleDownloadClick}
            disabled={isLoading}
          >
            {isLoading ? "Processing..." : "Download Data"}
          </Button>

          {/* Save to DB Button */}
          <Button
            variant="contained"
            color="primary"
            fullWidth
            sx={{
              mb: 2,
              py: 1.5,
              fontWeight: "bold",
              backgroundColor: "#1976d2",
              "&:hover": {
                backgroundColor: "#115293",
              },
            }}
            onClick={handleSaveToDB}
            disabled={isLoading}
          >
            {isLoading ? "Processing..." : "Save To DB"}
          </Button>

          {/* Status or Error Messages */}
          {statusMsg && (
            <Typography variant="body1" color="success.main" sx={{ mb: 2, textAlign: "center" }}>
              {statusMsg}
            </Typography>
          )}
          {error && (
            <Typography variant="body1" color="error" sx={{ mb: 2, textAlign: "center" }}>
              {error}
            </Typography>
          )}
        </Box>
      </Box>
      {/* Data Table */}
      <Box
        sx={{
          height: { xs: "60vh", sm: "70vh", md: "75vh" },
          display: "flex",
          flexDirection: "column",
          justifyContent: "flex-start",
          alignItems: "center",
          px: 2,
        }}
      >
        <Card
          sx={{
            mt: 3,
            mb:3,
            boxShadow: 2,
            borderRadius: "3px",
            width: "100%",
            flexGrow: 1,
            display: "flex",
            flexDirection: "column",
            backgroundColor: "#ffffff",
          }}
        >
          <TableContainer
            component={Paper}
            sx={{
              maxHeight: "100%",
              overflowX: "auto",
              overflowY: "auto",
              scrollBehavior: "smooth",
              flexGrow: 1,
              width: "100%",
            }}
          >
            <Table stickyHeader>
              <TableHead>
                <TableRow>
                  {currentHeaders.map((header) => (
                    <TableCell
                      key={header}
                      sx={{
                        fontWeight: "bold",
                        color: "#ffffff",
                        backgroundColor: "#83d600",
                        textAlign: "center",
                        borderLeft: "1px solid #e0e0e0",
                        position: "sticky",
                        top: 0,
                        zIndex: 1,
                        padding: "8px",
                        cursor: "pointer",
                      }}
                      onClick={() => handleSort(header)}
                    >
                      {header.replace(/([A-Z])/g, " $1").trim()}{" "}
                      {/* Add spaces before capital letters */}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {sortedData.map((row, index) => (
                  <TableRow
                    key={index}
                    hover
                    sx={{
                      border: "1px solid #e0e0e0",
                    }}
                  >
                    {currentHeaders.map((header) => (
                      <TableCell
                        key={header}
                        sx={{
                          textAlign: "center",
                          padding: "8px",
                          borderLeft: "1px solid #e0e0e0",
                        }}
                      >
                        {row[header]}
                      </TableCell>
                    ))}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Card>
      </Box>
    </>
  );
};

export default Main;
