// src/pages/watchlist.js

import React, { useState, useEffect, useMemo, useCallback } from "react";
import {
  Autocomplete,
  TextField,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Box,
  Typography,
  CircularProgress,
  IconButton,
  Tooltip,
  ListItem,
  Snackbar,
  Alert,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
import { styled, useTheme } from "@mui/material/styles";
import axios from "axios";
import DeleteIcon from "@mui/icons-material/Delete";
import HomePage from "./HomePage";
import { debounce } from "lodash";
import { HubConnectionBuilder, LogLevel } from "@microsoft/signalr";
import { openDB } from "idb";

const DB_NAME = "TableHeadersDB";
const STORE_NAME = "HeadersStore";
// Styled components for better readability and maintainability
const StyledTableCell = styled(TableCell)(({ theme }) => ({
  fontWeight: "bold",
  color: theme.palette.common.white,
  backgroundColor: theme.palette.primary.main,
  textAlign: "center",
  borderLeft: `1px solid ${theme.palette.divider}`,
  userSelect: "none",
  position: "sticky",
  top: 0,
  zIndex: 1,
}));

const StyledTableRow = styled(TableRow)(({ theme, index }) => ({
  backgroundColor:
    index % 2 === 0
      ? theme.palette.action.hover
      : theme.palette.background.default,
  "&:hover": {
    backgroundColor: theme.palette.action.selected,
  },
}));

const RemoveButton = styled(IconButton)(({ theme }) => ({
  color: theme.palette.error.main,
}));

const Watchlist = () => {
  const theme = useTheme();

  // State for Autocomplete selection
  const [selectedSymbol, setSelectedSymbol] = useState(null);
  const [inputValue, setInputValue] = useState("");
  const [symbolOptions, setSymbolOptions] = useState([]);
  const [loading, setLoading] = useState(false);
  const [searchError, setSearchError] = useState(null);

  // State for watchlist
  const [watchlist, setWatchlist] = useState([]);
  const [watchlistLoading, setWatchlistLoading] = useState(false);
  const [watchlistError, setWatchlistError] = useState(null);
  const [selectedCategory, setSelectedCategory] = useState("");

  // State for notifications
  const [notification, setNotification] = useState({
    open: false,
    message: "",
    severity: "success",
  });

  // Initialize SignalR connection
  const [connection, setConnection] = useState(null);

  // Helper function to format symbols
  const formatSymbol = (symbol) => {
    return symbol.startsWith("NSE:") ? symbol.slice(4) : symbol;
  };

  // Fetch watchlist from backend on mount
  useEffect(() => {
    fetchWatchlist();
  }, []);

  const fetchWatchlist = async () => {
    setWatchlistLoading(true);
    setWatchlistError(null);
    try {
      const userId = localStorage.getItem("userId");
      const token = localStorage.getItem("token"); // Assuming token is stored here

      const response = await axios.get(
        "https://api.arasu.online/api/Watchlist/get_watchlist_with_ltp", // Ensure this endpoint matches your backend
        {
          headers: {
            Authorization: `Bearer ${token}`, // Include JWT token if required
          },
          params: { userId: userId },
        }
      );

      if (response.data && Array.isArray(response.data.watchlist)) {
        // Initialize each watchlist item with an ltp field
        const initializedWatchlist = response.data.watchlist.map((item) => ({
          ...item,
          ltp: 0.0, // Placeholder; will be updated via SignalR
          closePrice: item.closePrice,
        }));
        setWatchlist(initializedWatchlist);
      } else {
        setWatchlist([]);
      }
    } catch (err) {
      console.error("Error loading watchlist:", err);
      setWatchlistError("Failed to load watchlist.");
    } finally {
      setWatchlistLoading(false);
    }
  };

  
  useEffect(() => {
    // Create a new connection instance
    const connection = new HubConnectionBuilder()
      .withUrl("https://api.arasu.online/stockHub") // Update this URL if different
      .withAutomaticReconnect()
      .configureLogging(LogLevel.Information)
      .build();

    // Define connection event handlers
    connection.onreconnecting((error) => {
      console.warn(`Reconnecting due to error: ${error}`);
    });

    connection.onreconnected((connectionId) => {
      console.log(`Reconnected with connection ID: ${connectionId}`);
    });

    connection.onclose((error) => {
      console.error(`Connection closed due to error: ${error}`);
    });

    // Receive stock prices from the server
    connection.on("ReceiveStockPrices", (receivedStockPrices) => {
      setWatchlist((prevWatchlist) =>
        prevWatchlist.map((item) => {
          const receivedSymbol = item.symTicker.trim().toUpperCase();
          const receivedData = receivedStockPrices.open[receivedSymbol];


          let LTP = item.ltp;

          const receivedOpen = receivedStockPrices.lastOpen[receivedSymbol];
          const receivedHigh = receivedStockPrices.high[receivedSymbol];
          const receivedLow = receivedStockPrices.low[receivedSymbol];
          const LTPClose = receivedStockPrices.close[receivedSymbol];

          const P_O = (receivedOpen - LTPClose).toFixed(2);
          const P_H = (receivedHigh - LTPClose).toFixed(2);
          const P_L = (receivedLow - LTPClose).toFixed(2);
          const P_LTP = (LTP - LTPClose).toFixed(2);

          const LTP_O = (LTP - receivedOpen).toFixed(2);
          const LTP_H = (LTP - receivedHigh).toFixed(2);
          const LTP_L = (LTP - receivedLow).toFixed(2);

          const TH_OPEN = (receivedOpen - LTPClose).toFixed(2);
          const TH_HIGH = (receivedHigh - LTPClose).toFixed(2);
          const TH_LOW = (receivedLow - LTPClose).toFixed(2);
          const TH_LTP = (LTP - LTPClose).toFixed(2);

          if (LTP === 0 || LTP === null) {
           
            if (receivedData) {
              const fifteenMinOpen = receivedData;
              const LtpMin15minOpen = (LTPClose - fifteenMinOpen).toFixed(2);

              return {
                ...item,
                fifteenMinOpen: LtpMin15minOpen,
                ltp: LTPClose,
                open: receivedOpen,
                highPrice: receivedHigh,
                lowPrice: receivedLow,
                p_o: P_O,
                p_h: P_H,
                p_l: P_L,
                p_ltp: P_LTP,
                ltp_o: LTP_O,
                ltp_h: LTP_H,
                ltp_l: LTP_L,
                th_o: TH_OPEN,
                th_h: TH_HIGH,
                th_l: TH_LOW,
                th_ltp: TH_LTP,
              };
            } else {
              return item;
            }
          } else {

          
            if (receivedData) {
              const fifteenMinOpen = receivedData;
              const LtpMin15minOpen = (LTP - fifteenMinOpen).toFixed(2);
  
              return {
                ...item,
                fifteenMinOpen: LtpMin15minOpen,
              };
            } else {
              return item;
            }
          }

         
        })
      );
    });

    // Start the connection
    connection
      .start()
      .then(() => {
        console.log("Connected to StockHub");
      })
      .catch((err) => {
        console.error("Connection failed: ", err.toString());
      });

    // Clean up the connection when the component unmounts
    return () => {
      connection
        .stop()
        .then(() => console.log("Connection stopped"))
        .catch((err) =>
          console.error("Error stopping connection: ", err.toString())
        );
    };
  }, []);

  // Initialize SignalR connection
  useEffect(() => {
    const token = localStorage.getItem("token"); // Assuming token is stored here
    const newConnection = new HubConnectionBuilder()
      .withUrl("https://api.arasu.online/ltpHub", {
        accessTokenFactory: () => token, // Include token if SignalR hub requires authentication
      }) // Ensure this URL matches your backend hub route
      .withAutomaticReconnect()
      .configureLogging(LogLevel.Information)
      .build();

    setConnection(newConnection);
  }, []);

  // Start SignalR connection and set up listeners
  useEffect(() => {
    if (connection) {
      connection
        .start()
        .then(() => {
          console.log("Connected to SignalR hub");
          // Subscribe to ReceiveLtp event
          connection.on("ReceiveLtp", (message) => {

            // Option 1: If using Option 1 from backend (symbol and ltp at top level)
            const receivedSymbol = message.symbol.trim().toUpperCase();

            const receivedLtp = message.ltp;

            const receivedOpen = message.open;
            const receivedHigh = message.highPrice;
            const receivedLow = message.lowPrice;
            const receivedPrevClose = message.previousClose;

            const P_O = (receivedOpen - receivedPrevClose).toFixed(2);
            const P_H = (receivedHigh - receivedPrevClose).toFixed(2);
            const P_L = (receivedLow - receivedPrevClose).toFixed(2);
            const P_LTP = (receivedLtp - receivedPrevClose).toFixed(2);

            const LTP_O = (receivedLtp - receivedOpen).toFixed(2);
            const LTP_H = (receivedLtp - receivedHigh).toFixed(2);
            const LTP_L = (receivedLtp - receivedLow).toFixed(2);

            setWatchlist((prevWatchlist) =>
              prevWatchlist.map((item) => {
                const itemSymbol = item.symTicker.trim().toUpperCase();
                const isMatch = itemSymbol === receivedSymbol;

            

                if (isMatch) {
                  const TH_OPEN = (receivedOpen - item.closePrice).toFixed(2);
                  const TH_HIGH = (receivedHigh - item.closePrice).toFixed(2);
                  const TH_LOW = (receivedLow - item.closePrice).toFixed(2);
                  const TH_LTP = (receivedLtp - item.closePrice).toFixed(2);

                  return {
                    ...item,
                    ltp: receivedLtp,
                    open: receivedOpen,
                    highPrice: receivedHigh,
                    lowPrice: receivedLow,
                    p_o: P_O,
                    p_h: P_H,
                    p_l: P_L,
                    p_ltp: P_LTP,
                    ltp_o: LTP_O,
                    ltp_h: LTP_H,
                    ltp_l: LTP_L,
                    th_o: TH_OPEN,
                    th_h: TH_HIGH,
                    th_l: TH_LOW,
                    th_ltp: TH_LTP,
                  };
                } else {
                  return item;
                }
              })
            );
          });

          // Optional: Handle reconnection events
          connection.onreconnecting((error) => {
            console.warn("SignalR reconnecting:", error);
            setNotification({
              open: true,
              message: "Reconnecting to real-time data...",
              severity: "warning",
            });
          });

          connection.onreconnected((connectionId) => {
            console.log("SignalR reconnected. Connection ID:", connectionId);
            setNotification({
              open: true,
              message: "Reconnected to real-time data.",
              severity: "success",
            });
          });

          connection.onclose((error) => {
            console.error("SignalR connection closed:", error);
            setNotification({
              open: true,
              message: "Disconnected from real-time data.",
              severity: "error",
            });
          });
        })
        .catch((err) => {
          console.error("Failed to connect to SignalR hub:", err);
          setNotification({
            open: true,
            message: "Failed to connect to real-time data.",
            severity: "error",
          });
        });

      // Cleanup on unmount
      return () => {
        connection
          .stop()
          .then(() => console.log("Disconnected from SignalR hub"))
          .catch((err) =>
            console.error("Error disconnecting from SignalR hub:", err)
          );
      };
    }
  }, [connection]);

  // Log watchlist updates
  useEffect(() => {
    // console.log("Watchlist state updated:", watchlist);
  }, [watchlist]);

  // Add symbol to watchlist and save to backend
  const handleSymbolChange = useCallback(
    (event, newValue) => {
      if (
        newValue &&
        !watchlist.some((item) => item.symTicker === newValue.symTicker)
      ) {
        console.log(newValue);
        const updatedWatchlist = [
          ...watchlist,
          { symTicker: newValue.symTicker, ltp: 0.0 }, // Initialize LTP
        ];

        setWatchlist(updatedWatchlist);
        setSelectedSymbol(null);
        setInputValue("");

        const userId = localStorage.getItem("userId");
        const token = localStorage.getItem("token"); // Assuming token is stored here

        console.log(newValue.symTicker);

        // Add to backend
        axios
          .post(
            "https://api.arasu.online/api/Watchlist/add_to_watchlist/", // Ensure this endpoint matches your backend
            {
              symTicker: newValue.symTicker,
              userId: userId,
            },
            {
              headers: {
                Authorization: `Bearer ${token}`, // Include JWT token if required
              },
            }
          )
          .then((res) => {
            setNotification({
              open: true,
              message: `Added ${formatSymbol(
                newValue.symTicker
              )} to watchlist.`,
              severity: "success",
            });

            fetchWatchlist();
          })
          .catch((error) => {
            console.error("Error adding symbol to watchlist:", error);
            setNotification({
              open: true,
              message: "Failed to add symbol to watchlist.",
              severity: "error",
            });
            // Revert the state change
            setWatchlist((prev) =>
              prev.filter((item) => item.symTicker !== newValue.symTicker)
            );
          });
      }
    },
    [watchlist]
  );

  // Remove symbol from watchlist and backend
  const handleRemoveFromWatchlist = useCallback(
    (symTicker) => {
      const updatedWatchlist = watchlist.filter(
        (item) => item.symTicker !== symTicker
      );
      setWatchlist(updatedWatchlist);

      const userId = localStorage.getItem("userId");
      const token = localStorage.getItem("token"); // Assuming token is stored here

      // Remove from backend
      axios
        .post(
          "https://api.arasu.online/api/Watchlist/remove_from_watchlist/", // Ensure this endpoint matches your backend
          {
            symTicker,
            userId: userId,
          },
          {
            headers: {
              Authorization: `Bearer ${token}`, // Include JWT token if required
            },
          }
        )
        .then((res) => {
          setNotification({
            open: true,
            message: `Removed ${formatSymbol(symTicker)} from watchlist.`,
            severity: "info",
          });
          // No need to remove LTP data as it's integrated into watchlist
        })
        .catch((error) => {
          console.error("Error removing symbol from watchlist:", error);
          setNotification({
            open: true,
            message: "Failed to remove symbol from watchlist.",
            severity: "error",
          });
          // Revert the state change
          setWatchlist((prev) => [...prev, { symTicker, ltp: 0.0 }]);
        });
    },
    [watchlist]
  );

  // Debounce the API call to prevent excessive requests
  const fetchSymbolOptions = useMemo(
    () =>
      debounce(async (query) => {
        if (!query || query.trim().length < 1) {
          setSymbolOptions([]);
          return;
        }
        setLoading(true);
        setSearchError(null);
        try {
          const token = localStorage.getItem("token"); // Assuming token is stored here
          const response = await axios.get(
            "https://api.arasu.online/api/Symbol/Search", // Ensure this endpoint matches your backend
            {
              headers: {
                Authorization: `Bearer ${token}`, // Include JWT token if required
              },
              params: { query: query },
            }
          );

          if (response.data && Array.isArray(response.data)) {
            setSymbolOptions(response.data);
          } else {
            setSymbolOptions([]);
          }
        } catch (err) {
          console.error("Error fetching symbol options:", err);
          setSearchError("Failed to fetch symbols.");
        } finally {
          setLoading(false);
        }
      }, 300),
    []
  );

  // Handle input changes in the Autocomplete
  const handleInputChange = (event, newInputValue) => {
    setInputValue(newInputValue);
    fetchSymbolOptions(newInputValue);
  };

  const defaultHeaders = [
    { label: "Symbol Ticker", value: "symTicker", visible: true },
    { label: "Open", value: "open", visible: true },
    { label: "High", value: "highPrice", visible: true },
    { label: "Low", value: "lowPrice", visible: true },
    { label: "LTP", value: "ltp", visible: true },
    { label: "15min_Open", value: "fifteenMinOpen", visible: true },
    { label: "P.O", value: "p_o", visible: true },
    { label: "P.H", value: "p_h", visible: true },
    { label: "P.L", value: "p_l", visible: true },
    { label: "P.LTP", value: "p_ltp", visible: true },
    { label: "LTP.O", value: "ltp_o", visible: true },
    { label: "LTP.H", value: "ltp_h", visible: true },
    { label: "LTP.L", value: "ltp_l", visible: true },
    { label: "TH_OPEN", value: "th_o", visible: true },
    { label: "TH_HIGH", value: "th_h", visible: true },
    { label: "TH_LOW", value: "th_l", visible: true },
    { label: "TH_LTP", value: "th_ltp", visible: true },
    { label: "Actions", value: "actions", visible: true },
  ];

  const [tableHeaders, setTableHeaders] = useState(defaultHeaders);

  const initializeDB = async () => {
    const db = await openDB(DB_NAME, 1, {
      upgrade(db) {
        if (!db.objectStoreNames.contains(STORE_NAME)) {
          db.createObjectStore(STORE_NAME, { keyPath: "id" });
        }
      },
    });
    return db;
  };

  const saveHeadersToDB = async (headers) => {
    const db = await initializeDB();
    await db.put(STORE_NAME, { id: "headers", headers });
  };

  // Fetch headers from IndexedDB
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const fetchHeadersFromDB = async () => {
    const db = await initializeDB();
    const savedData = await db.get(STORE_NAME, "headers");
    if (savedData) {
      setTableHeaders(savedData.headers);
    } else {
      saveHeadersToDB(defaultHeaders); // Save default headers if none are saved
    }
  };

  const toggleHeaderVisibility = (headerValue, visible) => {
    const updatedHeaders = tableHeaders.map((header) =>
      header.value === headerValue ? { ...header, visible } : header
    );
    setTableHeaders(updatedHeaders);
    saveHeadersToDB(updatedHeaders); // Save updates to IndexedDB
  };

  useEffect(() => {
    fetchHeadersFromDB();
  }, [fetchHeadersFromDB]);

  const addSymbol = async (symbol) => {
    const userId = localStorage.getItem("userId");
    const token = localStorage.getItem("token"); // Assuming token is stored here

    try {
      const response = await axios.post(
        "https://api.arasu.online/api/Watchlist/add_to_watchlist/",
        {
          symTicker: symbol.name.replace("NSE:", ""), // Sending the 'name' field as 'symTicker'
          userId: userId,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`, // Include JWT token if required
            "Content-Type": "application/json", // Ensure the content type is set
          },
        }
      );
      console.log(response);
      setNotification({
        open: true,
        message: `Symbol ${symbol.name} added to watchlist.`,
        severity: "success",
      });

      setWatchlist((prev) => [...prev, { symTicker: symbol.name }]);
    } catch (error) {
      console.error("Error adding symbol to watchlist:", error);

      // Log detailed error response from the server
      if (error.response) {
        console.error("Backend response:", error.response.data);
        setNotification({
          open: true,
          message: `Failed to add symbol ${symbol.name} to watchlist: ${
            error.response.data.message || "Server Error"
          }.`,
          severity: "error",
        });
      } else if (error.request) {
        console.error("No response received:", error.request);
        setNotification({
          open: true,
          message: "No response from server. Please try again later.",
          severity: "error",
        });
      } else {
        console.error("Error setting up request:", error.message);
        setNotification({
          open: true,
          message: `Error: ${error.message}`,
          severity: "error",
        });
      }

      // Optionally, you can handle reverting state changes here
    }
  };

  // Handler for category change (assuming you have a category selection)
  const handleCategoryChange = (event) => {
    const selectedValue = event.target.value;
    setSelectedCategory(selectedValue);

    fetchSymbols(selectedValue);
  };

  const fetchSymbols = async (tabIndex) => {
    setLoading(true);
    try {
      const response = await axios.get(
        `https://api.arasu.online/api/BulkSymbols?listId=${tabIndex}`
      );

      const symbollist = response.data; // Array of symbols

      const BATCH_SIZE = 1;
      const DELAY_MS = 100; // 1 second

      for (let i = 0; i < symbollist.length; i += BATCH_SIZE) {
        const batch = symbollist.slice(i, i + BATCH_SIZE);

        // Process each symbol with individual error handling
        await Promise.all(
          batch.map(async (symbol) => {
            try {
              await addSymbol(symbol);
            } catch (err) {
              console.error(`Failed to add symbol ${symbol}:`, err);

              // Optionally, you can collect failed symbols and handle them later
            }
          })
        );

        if (i + BATCH_SIZE < symbollist.length) {
          await new Promise((resolve) => setTimeout(resolve, DELAY_MS));
        }
      }
    } catch (error) {
      console.error("Error fetching symbols:", error);
      setNotification({
        open: true,
        message: "Failed to fetch symbols.",
        severity: "error",
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <HomePage />
      <Box
        sx={{
          minHeight: "100vh",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          padding: theme.spacing(4),
          backgroundColor: theme.palette.background.default,
        }}
      >
        {/* Watchlist Loading and Error States */}
        {watchlistLoading && (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              marginBottom: theme.spacing(2),
            }}
          >
            <CircularProgress size={24} />
            <Typography variant="body1" sx={{ marginLeft: theme.spacing(1) }}>
              Loading watchlist...
            </Typography>
          </Box>
        )}
        {watchlistError && (
          <Typography
            variant="body2"
            color="error"
            sx={{ marginBottom: theme.spacing(2), textAlign: "center" }}
          >
            {watchlistError}
          </Typography>
        )}

        {/* Autocomplete and Category Selection */}
        <Box
          sx={{
            width: "100%",
            marginBottom: theme.spacing(4),
            display: "flex",
            alignItems: "center",
            gap: theme.spacing(2), // Adds space between dropdown and Autocomplete
            flexWrap: "wrap",
          }}
        >
          <Autocomplete
            options={symbolOptions}
            getOptionLabel={(option) => option.symTicker || ""}
            value={selectedSymbol}
            onChange={handleSymbolChange}
            inputValue={inputValue}
            onInputChange={handleInputChange}
            loading={loading}
            renderOption={(props, option) => (
              <ListItem {...props} key={option.symTicker}>
                {option.symTicker}
              </ListItem>
            )}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Search Symbol"
                variant="outlined"
                fullWidth
                InputProps={{
                  ...params.InputProps,
                  endAdornment: (
                    <>
                      {loading ? (
                        <CircularProgress color="inherit" size={20} />
                      ) : null}
                      {params.InputProps.endAdornment}
                    </>
                  ),
                }}
              />
            )}
            noOptionsText={
              inputValue.trim().length < 1
                ? "Type at least 1 character"
                : "No symbols found"
            }
            sx={{ minWidth: { xs: "100%", sm: 300, md: 400 } }}
          />
          {searchError && (
            <Typography
              variant="body2"
              color="error"
              sx={{ marginTop: theme.spacing(2), textAlign: "center" }}
            >
              {searchError}
            </Typography>
          )}

          <FormControl
            sx={{
              minWidth: { xs: "100%", sm: 220 },
              mt: { xs: 2, sm: 0 },
            }}
          >
            <InputLabel id="category-select-label">Category</InputLabel>
            <Select
              labelId="category-select-label"
              id="category-select"
              value={selectedCategory}
              label="Category"
              onChange={handleCategoryChange}
            >
              <MenuItem value="1">Symbol List 1</MenuItem>
              <MenuItem value="2">Symbol List 2</MenuItem>
              <MenuItem value="3">Symbol List 3</MenuItem>
              <MenuItem value="4">Symbol List 4</MenuItem>
            </Select>
          </FormControl>

          {/* table header */}

          <FormControl sx={{ minWidth: 230 }}>
            <Select
              multiple
              value={tableHeaders
                .filter((header) => header.visible)
                .map((h) => h.value)}
              onChange={(event) => {
                const selectedValues = event.target.value;
                tableHeaders.forEach((header) => {
                  toggleHeaderVisibility(
                    header.value,
                    selectedValues.includes(header.value)
                  );
                });
              }}
              renderValue={(selected) =>
                selected
                  .map(
                    (value) =>
                      tableHeaders.find((header) => header === value)?.label
                  )
                  .join(" ")
              }
            >
              {tableHeaders.map((header) => (
                <MenuItem key={header.value} value={header.value}>
                  <input
                    type="checkbox"
                    checked={header.visible}
                    onChange={() =>
                      toggleHeaderVisibility(header.value, !header.visible)
                    }
                  />
                  {header.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>

        {/* Watchlist Table */}
        <Box sx={{ width: "100%", overflowX: "auto" }}>
          <TableContainer
            component={Paper}
            sx={{ mb: 2, mt: 4, maxHeight: 700, overflowX: "auto" }}
          >
            <Table stickyHeader aria-label="watchlist table">
              <TableHead>
                <TableRow>
                  {tableHeaders
                    .filter((header) => header.visible)
                    .map((header) => (
                      <StyledTableCell key={header.value}>
                        {header.label}
                      </StyledTableCell>
                    ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {watchlist.map((row, index) => (
                  <StyledTableRow key={index}>
                    {tableHeaders
                      .filter((header) => header.visible)
                      .map((header) => (
                        <TableCell key={header.value} align="center">
                          {header.value === "actions" ? (
                            <Tooltip title="Remove from Watchlist">
                              <RemoveButton
                                onClick={() =>
                                  handleRemoveFromWatchlist(row.symTicker)
                                }
                              >
                                <DeleteIcon />
                              </RemoveButton>
                            </Tooltip>
                          ) : (
                            row[header.value] || "-"
                          )}
                        </TableCell>
                      ))}
                  </StyledTableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>

        {/* Notification Snackbar */}
        <Snackbar
          open={notification.open}
          autoHideDuration={6000}
          onClose={() => setNotification({ ...notification, open: false })}
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        >
          <Alert
            onClose={() => setNotification({ ...notification, open: false })}
            severity={notification.severity}
            sx={{ width: "100%" }}
          >
            {notification.message}
          </Alert>
        </Snackbar>
      </Box>
    </>
  );
};

export default Watchlist;
